<template>
  <div class="card">
    <header class="card-header">
      <span class="card-header-title is-clickable" @click="toggleExpanded">
        {{ title }}

        <Icon v-if="showExclamation" name="icon-alert-circle" class="has-text-danger" />
      </span>

      <a href="#" class="card-header-icon" aria-label="more options">
        <Chevron
          :is-expanded="expanded"
          @toggle-expanded="toggleExpanded">
        </Chevron>
      </a>
    </header>

    <div v-if="expanded" class="card-content">
      <div v-show="expanded">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    showExclamation: {
      type: Boolean,
      default: false
    },

    isExpanded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: this.isExpanded
    }
  },

  watch: {
    isExpanded () {
      this.expanded = this.isExpanded
    }
  },

  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 1.5rem;
}

.card-content :last-child {
  margin-bottom: 0;
}
</style>
