<template>
  <MapMarker
    :name="name"
    :icon="icon"
    :location="marker.location"
    :visible="marker.visible"
    :clickable="clickable"
    :opacity="opacity"
    @click="onClick">
  </MapMarker>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      userToAssign: 'hunt/assignments/userToAssign'
    }),

    name () {
      if (this.marker.user !== null) {
        return `${this.marker.name} (${this.marker.user.getFullName()})`
      }

      return this.marker.name
    },

    icon () {
      return this.marker.user ? getMarkerTypeIconUrl('hunter-safe') : getMarkerTypeIconUrl(this.marker.type)
    },

    opacity () {
      // During assignment lower opacity on non-assignable markers
      if (this.userToAssign !== null) {
        return this.isPassOrTower() ? 1 : 0.5
      }

      return 1
    },

    clickable () {
      if (this.userToAssign !== null) {
        return this.isPassOrTower() && this.marker.user === null
      }

      return false
    }
  },

  methods: {
    onClick () {
      if (this.userToAssign) {
        this.assignUser()
      }
    },

    async assignUser () {
      const assignment = this.userToAssign
      assignment.markerId = this.marker.id
      const prevUser = this.marker.user

      this.marker.user = assignment

      this.$store.commit('hunt/assignments/userToAssign', null)

      try {
        await this.$store.dispatch('hunt/drives/updateAssignment', assignment)
      } catch (error) {
        this.marker.user = prevUser
        this.$notification.danger('Ett fel inträffade och det gick inte att välja pass eller torn. Vänligen försök igen.', 5000)
      }
    },

    isPassOrTower () {
      return this.marker.type === 'pass' || this.marker.type === 'tower'
    }
  }
}
</script>
