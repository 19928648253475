import { mapGetters } from 'vuex'
import HunterModel from '@/api/hunt/playback/hunterModel'

export default {
  data () {
    return {
      interval: null,
      hunterCache: [],
      gpsDeviceCache: [],
      lastHunterLocationTimestamp: 0,
      lastGpsDeviceLocationTimestamp: 0
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      events: 'hunt/events/events',
      playbackStatus: 'hunt/playback/status',
      messages: 'hunt/chat/messages',
      participants: 'hunt/playback/participants',
      hunters: 'hunt/playback/hunters',
      hunterLocations: 'hunt/playback/hunterLocations',
      gpsDevices: 'hunt/playback/gpsDevices',
      gpsLocations: 'hunt/playback/gpsLocations',
      markers: 'hunt/map/markers'
    }),

    elapsedTime: {
      get () {
        return this.$store.getters['hunt/playback/elapsedTime']
      },

      set (elapsedTime) {
        return this.$store.dispatch('hunt/playback/elapsedTime', elapsedTime)
      }
    },

    duration: {
      get () {
        return this.$store.getters['hunt/playback/duration']
      },

      set (duration) {
        return this.$store.dispatch('hunt/playback/duration', duration)
      }
    },

    speed: {
      get () {
        return this.$store.getters['hunt/playback/speed']
      }
    },

    elapsedInMs () {
      return this.hunt.startsAt.getTime() + (this.elapsedTime * 1000)
    }
  },

  watch: {
    playbackStatus (status) {
      switch (status) {
        case 'started':
          this.started()
          break

        case 'paused':
          this.paused()
          break

        case 'stopped':
          this.stopped()
          break
      }
    },

    speed () {
      if (this.interval !== null) {
        this.stopTimer()
        this.startTimer()
      }
    },

    elapsedTime () {
      this.animateChatMessages()
      this.animateEvents()
      this.animateHunterPositions()
      this.animateGpsPositions()
    }
  },

  render () {
    return ''
  },

  created () {
    // Every other refresh I get an error message complaining about
    // this.hunterLocations, this.gpsDevices and so on.
    // I don't really wanna destroy things further, so I'm leaving it for now.

    this.initializeHunters()
    this.initializeGpsDevices()
    this.initializePlayback()
    this.initializeMarkers()

    this.resetChat(false)
    this.resetEvents(false)
  },

  destroyed () {
    this.stopTimer()

    this.resetChat(true)
    this.resetEvents(true)
  },

  methods: {
    initializePlayback () {
      // Playback
      var startTimeInSeconds = this.hunt.startsAt.getTime() / 1000
      var endTimeInSeconds = this.getEndTimeFromLocationUpdates() / 1000

      this.duration = Math.round(endTimeInSeconds - startTimeInSeconds)
      this.elapsedTime = 0

      this.$store.commit('hunt/playback/stop')

      // Prepare hunt and gps cache
      this.hunters.forEach(hunter => {
        this.hunterCache[hunter.id] = hunter
      })

      this.gpsDevices.forEach(gpsDevice => {
        this.gpsDeviceCache[gpsDevice.id] = gpsDevice
      })
    },

    initializeHunters () {
      const participants = this.$store.getters['hunt/participants/participants'].filter(participant => participant.status === 'member')
      const hunters = participants.map(participant => {
        return new HunterModel(participant.id, participant.firstName + ' ' + participant.lastName)
      })

      hunters.forEach(hunter => {
        hunter.trail = this.hunterLocations.filter(hunterLocation => hunterLocation.userId === hunter.id)
      })

      this.$store.commit('hunt/playback/setHunters', hunters)
    },

    initializeGpsDevices () {
      this.gpsDevices.forEach(gpsDevice => {
        gpsDevice.trail = this.gpsLocations.filter(gpsLocation => gpsLocation.deviceId === gpsDevice.id)
        gpsDevice.color = gpsDevice.trail.length > 0 ? gpsDevice.trail[0].color : 'red'
      })
    },

    getEndTimeFromLocationUpdates () {
      const lastHunterTimestamp = this.hunterLocations.length > 0 ? this.hunterLocations[this.hunterLocations.length - 1].timestamp : 0
      const lastGpsTimestamp = this.gpsLocations.length > 0 ? this.gpsLocations[this.gpsLocations.length - 1].timestamp : 0

      if (lastHunterTimestamp === 0 && lastGpsTimestamp === 0) { // No location what so ever (should never happen)
        return this.hunt.endsAt.getTime()
      }

      return Math.max(lastHunterTimestamp, lastGpsTimestamp)
    },

    initializeMarkers () {
      this.markers.forEach(marker => {
        marker.visible = false
      })

      const checkIns = this.hunterLocations.filter(hunterLocation => hunterLocation.checkIn !== null)
      checkIns.forEach(hunterLocation => {
        const marker = this.getMarkerById(hunterLocation.checkIn.id)

        if (marker !== null) {
          marker.visible = true
          marker.name = hunterLocation.checkIn.name
          marker.location = hunterLocation.checkIn.location
        }
      })
    },

    getMarkerById (markerId) {
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].id === markerId) {
          return this.markers[i]
        }
      }

      return null
    },

    resetChat (state) {
      this.messages.forEach(message => {
        message.visible = state
      })
    },

    resetEvents (state) {
      this.events.forEach(event => {
        event.visible = state
      })
    },

    started () {
      this.startTimer()
    },

    paused () {
      this.stopTimer()
    },

    stopped () {
      this.stopTimer()
    },

    startTimer () {
      this.interval = setInterval(() => {
        this.advanceTime()
      }, 1000 / this.speed)
    },

    stopTimer () {
      if (this.interval !== null) {
        clearInterval(this.interval)
        this.interval = null
      }
    },

    advanceTime () {
      if (this.elapsedTime + 1 >= this.duration) {
        this.elapsedTime = this.duration
      } else {
        this.elapsedTime += 1
      }

      if (this.elapsedTime === this.duration) {
        this.$store.dispatch('hunt/playback/pause')
      }
    },

    animateChatMessages () {
      this.messages.forEach(message => {
        message.visible = this.elapsedInMs >= message.createdAt.getTime()
      })
    },

    animateEvents () {
      this.events.forEach(event => {
        event.visible = this.elapsedInMs >= event.createdAt.getTime()
      })
    },

    animateHunterPositions () {
      const hunterLocations = this.getEventForUpdate(this.hunterLocations, this.lastHunterLocationTimestamp)

      hunterLocations.forEach(hunterLocation => {
        this.animateHunterPosition(hunterLocation)
      })

      this.lastHunterLocationTimestamp = this.elapsedInMs
    },

    animateHunterPosition (hunterLocation) {
      const userId = hunterLocation.deviceId.substring(0, hunterLocation.deviceId.indexOf('_'))
      const hunter = this.hunterCache[userId]
      if (hunter === undefined) { // Hunter has been removed after the hunt
        return
      }

      hunter.active = true
      hunter.visible = true
      hunter.location = hunterLocation.location
      hunter.accuracy = hunterLocation.accuracy

      if (hunterLocation.checkIn !== null) {
        const marker = this.getMarkerById(hunterLocation.checkIn.id)
        if (marker !== null) {
          hunter.checkIn(marker)
        }
      } else {
        if (hunter.checkInMarker !== null) {
          hunter.checkOut()
        }

        hunter.status = hunterLocation.isPublishing ? 'active' : 'inactive'
      }

      const selectedParticipant = this.$store.getters['hunt/playback/selectedParticipant']
      if (selectedParticipant !== null && selectedParticipant === hunter) {
        this.$store.commit('map/setCenter', hunter.location)
      }
    },

    animateGpsPositions () {
      const gpsLocations = this.getEventForUpdate(this.gpsLocations, this.lastGpsDeviceLocationTimestamp)

      gpsLocations.forEach(gpsLocation => {
        this.animateGpsPosition(gpsLocation)
      })

      this.lastGpsDeviceLocationTimestamp = this.elapsedInMs
    },

    animateGpsPosition (gpsLocation) {
      const gpsDevice = this.gpsDeviceCache[gpsLocation.deviceId]

      if (!gpsDevice.active) {
        gpsDevice.active = true
        gpsDevice.visible = true
      }

      gpsDevice.location = gpsLocation.location
      gpsDevice.speed = gpsLocation.speed
      gpsDevice.color = gpsLocation.color

      const selectedParticipant = this.$store.getters['hunt/playback/selectedParticipant']
      if (selectedParticipant !== null && selectedParticipant === gpsDevice) {
        this.$store.commit('map/setCenter', gpsDevice.location)
      }
    },

    getEventForUpdate (locationUpdates, lastTimestamp) {
      if (this.elapsedInMs > lastTimestamp) {
        return this.getForwardsEvents(locationUpdates, lastTimestamp)
      } else {
        return this.getRewindindgEvents(locationUpdates, lastTimestamp)
      }
    },

    getForwardsEvents (locationUpdates, lastTimestamp) {
      return locationUpdates.filter(locationUpdate => {
        return locationUpdate.timestamp > lastTimestamp && locationUpdate.timestamp <= this.elapsedInMs
      })
    },

    getRewindindgEvents (locationUpdates, lastTimestamp) {
      return locationUpdates.filter(locationUpdate => {
        return locationUpdate.timestamp >= this.elapsedInMs && locationUpdate.timestamp < lastTimestamp
      }).reverse()
    },

    hasParticipant (userId) {
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].id === userId) {
          return true
        }
      }
      return false
    }
  }
}
