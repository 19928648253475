<template>
  <Fragment>
    <div v-if="visibleEvents.length > 0" class="events">
      <div
        v-for="event in events"
        v-show="event.visible"
        :key="event.id"
        class="box level is-mobile">
        <div class="level-left">
          <img :src="icon(event)" class="push-right" />

          <div>
            <h5 class="title is-5 is-spaced is-marginless">
              {{ event.name }}
            </h5>
            <p class="subtitle is-6 is-marginless">
              {{ date(event.createdAt) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <p v-if="visibleEvents.length === 0" class="has-text-grey no-events">
      {{ $t('hunt.noEventsYet') }}
    </p>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      events: 'hunt/events/events'
    }),

    events () {
      let events = this.$store.getters['hunt/events/events']
      return events.slice().sort((a, b) => a.createdAt - b.createdAt)
    },

    visibleEvents () {
      return this.events.filter(event => event.visible)
    }
  },

  methods: {
    date (date) {
      return this.hunt.isMultipleDays()
        ? this.$dayjs(date).format('D MMM HH:mm')
        : this.$dayjs(date).format('HH:mm')
    },

    icon (event) {
      return getMarkerTypeIconUrl(event.type)
    },

    toggleEventPanel () {
      this.$store.commit('hunt/playback/toggleEventPanel')
    }
  }
}
</script>

<style lang="scss" scoped>
.events {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
}

.box {
  padding: 0.75rem 1rem !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #f2f2f2;

  img {
    position: relative !important;
  }

  &:first-of-type {
    border-top: 1px solid #f2f2f2;
  }

  &:hover {
    background: #f7f7f7;
  }
}

p.no-events {
  padding: 1.5rem;
  margin: 0 !important;
}
</style>
