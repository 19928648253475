<template>
  <div
    :class="[classObject]"
    class="list-item level is-mobile is-marginless flex-important"
    @click="select">
    <div class="level-left">
      <div class="flex align-center">
        <figure class="image is-32x32 push-right-xs is-hidden-touch">
          <img :src="icon" />
        </figure>

        <div>
          <p class="title is-6 is-marginless">
            {{ hunter.name }}
          </p>

          <p v-if="hunter.checkInMarker" class="subtitle is-7 is-marginless">
            {{ hunter.checkInMarker.name }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="hunter.active" class="level-right">
      <AccuracyBars :meters="accuracy" :title="`${accuracy}m`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import AccuracyBars from './AccuracyBars.vue'

export default {
  components: {
    AccuracyBars
  },

  props: {
    hunter: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      selectedParticipant: 'hunt/playback/selectedParticipant'
    }),

    accuracy () {
      return Math.round(this.hunter.accuracy)
    },

    classObject () {
      return {
        'is-dimmed': !this.hunter.active,
        'is-clickable is-hoverable': this.hunter.active,
        'is-active': this.selected
      }
    },

    selected () {
      return this.selectedParticipant === this.hunter
    },

    icon () {
      const icons = {
        'inactive': getMarkerTypeIconUrl('hunter_off'),
        'active': getMarkerTypeIconUrl('hunter'),
        'checked-in': getMarkerTypeIconUrl('hunter_safe')
      }

      return icons[this.hunter.status]
    }
  },

  methods: {
    select () {
      if (!this.hunter.active) return
      this.$store.dispatch('hunt/playback/selectParticipant', !this.selected ? this.hunter : null)
    }
  }
}
</script>
