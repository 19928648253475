<template>
  <div class="playback-toolbar level is-mobile">
    <div class="level-left">
      <Button type="white" has-shadow @click="togglePanel">
        <Icon :name="icon" />
        <span>{{ title }}</span>
      </Button>
    </div>

    <div class="level-right">
      <div>
        <a class="delete is-large" @click="closeModal"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    icon () {
      return this.isActive ? 'icon-chevron-up' : 'icon-chevron-down'
    },

    title () {
      return this.isActive ? this.$t('hunt.hidePanelText') : this.$t('hunt.showPanelText')
    }
  },

  methods: {
    togglePanel () {
      if (this.isActive) {
        this.$emit('close-panel')
      } else {
        this.$emit('open-panel')
      }
    },

    closeModal () {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.playback-toolbar {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  left: 1.5rem;
  margin: 0 !important; // clear .level margin
  touch-action: none; // disable pinch-to-zoom iOS
}
</style>
