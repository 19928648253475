<template>
  <Table fullwidth hoverable bordered scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('general.name')" />
      <TableHeaderCell :heading="$t('general.role')" />
      <TableHeaderCell :heading="$t('hunt.assignments.stand')" />
    </TableHeader>

    <TableContent>
      <slot />
    </TableContent>
  </Table>
</template>

<script>
export default {
}
</script>
