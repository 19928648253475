<template>
  <div>
    <div class="columns">
      <div class="column has-text-centered">
        <Label :label="$t('hunt.startDate')" />
        <div>{{ startDate }}</div>
      </div>

      <div class="column has-text-centered">
        <Label :label="$t('hunt.startsAt')" />
        <div>{{ startsAt }}</div>
      </div>

      <div class="column has-text-centered">
        <Label :label="$t('hunt.endsAt')" />
        <div>{{ endsAt }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

export default {

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt'
    }),

    startDate () {
      const now = dayjs()
      const startsAt = dayjs(this.hunt.startsAt)
      const endsAt = dayjs(this.hunt.endsAt)

      if (startsAt.year() !== endsAt.year() || now.year() !== startsAt.year()) {
        return startsAt.format('dddd D MMMM YYYY')
      }

      return startsAt.format('dddd D MMMM')
    },

    startsAt () {
      return dayjs(this.hunt.startsAt).format('HH:mm')
    },

    endsAt () {
      const now = dayjs()
      const startsAt = dayjs(this.hunt.startsAt)
      const endsAt = dayjs(this.hunt.endsAt)

      if (startsAt.year() !== endsAt.year() || now.year() !== endsAt.year()) {
        return endsAt.format('dddd D MMMM YYYY, HH:mm')
      }

      if (startsAt.month() !== endsAt.month() || startsAt.date() !== endsAt.date()) {
        return endsAt.format('dddd D MMMM HH:mm')
      }

      return endsAt.format('HH:mm')
    }
  }
}
</script>
