<template>
  <div>
    <Heading size="6" :text="driveName" />

    <AssignmentSummarySectionTable>
      <AssignmentSummarySectionTableRow
        v-for="assignment in assignments"
        :key="assignment.id"
        :assignment="assignment">
      </AssignmentSummarySectionTableRow>
    </AssignmentSummarySectionTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AssignmentSummarySectionTable from './AssignmentSummarySectionTable.vue'
import AssignmentSummarySectionTableRow from './AssignmentSummarySectionTableRow.vue'

export default {
  components: {
    AssignmentSummarySectionTable,
    AssignmentSummarySectionTableRow
  },

  props: {
    drive: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      subAreas: 'hunt/map/subAreas'
    }),

    subArea () {
      if (this.subAreas !== null) {
        const i = this.subAreas.findIndex(subArea => subArea.id === this.drive.subAreaId)
        return i !== -1 ? this.subAreas[i] : null
      }
      return null
    },

    driveName () {
      if (this.drive.subAreaId !== null) {
        return this.subArea !== null ? this.subArea.name : ''
      }
      return this.$i18n.t('hunt.assignments.entireHuntArea')
    },

    assignments () {
      return this.drive.assignments.slice(0).sort((a, b) => a.getFullName().localeCompare(b.getFullName()))
    }
  }
}
</script>
