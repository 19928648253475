<template>
  <Table bordered fullwidth scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('hunt.huntableGames.species')" />
      <TableHeaderCell :heading="$t('hunt.huntableGames.comment')" />
    </TableHeader>

    <TableContent>
      <slot />
    </TableContent>
  </Table>
</template>

<script>
export default {
}
</script>
