<template>
  <SummarySection :title="$t('hunt.assignments.title')" :is-expanded="isInitiallyExpanded">
    <InlineLoader v-if="isLoading" />

    <div v-else>
      <AssignmentSummarySectionDrive
        v-for="(drive, i) in drives"
        :key="drive.id"
        :drive="drive"
        :class="{ 'push-bottom-xl': i < drives.length - 1 }">
      </AssignmentSummarySectionDrive>

      <span v-if="drives.length === 0">
        {{ $t('hunt.assignments.noAssignments') }}
      </span>
    </div>
  </SummarySection>
</template>

<script>
import { mapGetters } from 'vuex'
import SummarySection from './SummarySection.vue'
import AssignmentSummarySectionDrive from './AssignmentSummarySectionDrive.vue'

export default {
  components: {
    SummarySection,
    AssignmentSummarySectionDrive
  },

  computed: {
    ...mapGetters({
      drives: 'hunt/drives/getAll',
      subAreas: 'hunt/map/subAreas'
    }),

    isLoading () {
      return this.drives === null || this.subAreas === null
    },

    isInitiallyExpanded () {
      return this.drives !== null ? this.drives.length === 0 : false
    }
  }
}
</script>
