<script>
import { mapGetters } from 'vuex'
import LabelOverlay from '@/components/map/overlays/labelOverlay'
import colors from '@/components/map/utils/colors'

export default {
  props: {
    subArea: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      $map: null,
      $polygon: null,
      $polygonBounds: null,
      $label: null
    }
  },

  computed: {
    ...mapGetters({
      showSubAreaLabels: 'map/subAreas/showSubAreaLabels',
      showForbiddenLabels: 'map/subAreas/showForbiddenLabels',
      subAreas: 'hunt/map/subAreas',
      markers: 'hunt/map/markers',
      drives: 'hunt/drives/getAll',
      selectedDrive: 'hunt/drives/selectedDrive'
    }),

    drive () {
      if (this.drives === null) {
        return null
      }

      const i = this.drives.findIndex(drive => drive.subAreaId === this.subArea.id)
      return i !== -1 ? this.drives[i] : null
    },

    isSubAreaAssigned () {
      return this.drive !== null
    },

    visible () {
      return this.subArea.visible
    }
  },

  watch: {
    selectedDrive (newDrive, lastDrive) {
      if (this.isSubAreaAssigned) {
        if (newDrive === this.drive) {
          this.subArea.visible = true

          this.fitBounds()
          this.hideOtherSubAreas()
          this.updateMarkerAssignments(true)
        } else if (lastDrive === this.drive) {
          this.updateMarkerAssignments(false)
        }
      }

      if (newDrive == null) {
        this.subArea.visible = true
      }
    },

    visible (visible) {
      this.$polygon.setVisible(visible)
      this.updateLabelVisibility()
    },

    showSubAreaLabels () {
      this.updateLabelVisibility()
    },

    showForbiddenLabels () {
      this.updateLabelVisibility()
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const bounds = this.getBounds()

    this.$label = new LabelOverlay({
      position: bounds.getCenter(),
      text: this.subArea.name,
      map: this.$map,
      class: 'subarea-label-overlay'
    })

    const polygon = new window.google.maps.Polygon({
      map: this.$map,
      clickable: false,
      editable: false,
      paths: this.subArea.borderCoordinates,
      strokeColor: colors['subArea'],
      strokeWeight: 3,
      strokeOpacity: 1,
      fillColor: colors['subArea'],
      fillOpacity: 0
    })

    if (polygon) {
      this.$polygonBounds = this.getBounds()
      this.$polygon = polygon
    } else {
      console.error(`Failed to create polygon for subarea ${this.subArea.name}.`)
    }

    this.updateLabelVisibility()
  },

  destroyed () {
    if (this.$polygon) {
      this.$label.setMap(null)
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    hideOtherSubAreas () {
      this.subAreas.forEach(subArea => {
        if (subArea !== this.subArea) {
          subArea.visible = false
        }
      })
    },

    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.subArea.borderCoordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    },

    updateLabelVisibility () {
      const label = this.type === 'saat' ? this.showSubAreaLabels : this.showForbiddenLabels
      this.$label.setMap((label && this.visible ? this.$map : null))
    },

    fitBounds () {
      this.$map.fitBounds(this.getBounds())
    },

    updateMarkerAssignments (setOrClear) {
      this.drive.assignments.forEach(assignment => {
        if (assignment.markerId !== null) {
          const i = this.markers.findIndex(marker => marker.id === assignment.markerId)

          if (i !== -1) {
            this.markers[i].user = setOrClear ? assignment : null
          }
        }
      })
    }
  }
}
</script>
