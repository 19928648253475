<template>
  <div class="playback-controls-old box level is-mobile">
    <div class="level-left">
      <PlayPause />
      <Stop />
    </div>

    <div class="level-item">
      <Elapsed />
    </div>

    <Speed class="level-right is-hidden-touch" />
  </div>
</template>

<script>
import PlayPause from './components/PlayPause.vue'
import Stop from './components/Stop.vue'
import Elapsed from './components/Elapsed.vue'
import Speed from './components/Speed.vue'

export default {
  components: {
    PlayPause,
    Stop,
    Elapsed,
    Speed
  }
}
</script>

<style lang="scss">
.playback-controls-old {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 1;
  padding: 0 1.5rem;
  height: 5rem;
  margin: 0 !important;
  display: flex;
  align-items: center;
  touch-action: none; // disable pinch-to-zoom iOS

  @media screen and (max-width: 768px) {
    padding: 0 0.75rem;
  }

  .range-slider {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    width: 100%;
  }
}
</style>
