<template>
  <div :class="[className]" class="hunt-notification">
    <div class="columns">
      <div class="column">
        <p v-html="text"></p>
      </div>

      <div class="column is-narrow">
        <button class="button is-pulled-right" @click="cancel">
          {{ $t('general.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userToAssign: 'hunt/assignments/userToAssign'
    }),

    name () {
      return this.userToAssign && this.userToAssign.getFullName()
    },

    text () {
      return this.$t('hunt.assignNotification', { name: this.name })
    },

    className () {
      return this.userToAssign !== null ? 'is-active' : ''
    }
  },

  methods: {
    cancel () {
      this.$store.commit('hunt/assignments/userToAssign', null)
    }
  }
}
</script>

<style lang="scss">
.hunt-notification {
  background: rgba(#42555B, 0.95);
  color: white;
  padding: 1rem;
  border-radius: 0.25rem;

  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;

  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  left: 1.5rem;
  z-index: 10;

  strong {
    color: white;
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  .columns .column {
    display: flex;
    align-items: center;

    &.is-narrow {
      align-items: center;
      display: flex;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
