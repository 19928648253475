<template>
  <div :class="[activeClass]" class="hunt-notification">
    <div class="columns">
      <div class="column">
        <p>{{ $t('hunt.events.eventPanelHelpText') }}</p>
      </div>

      <div class="column is-narrow">
        <button class="button is-pulled-right" @click="cancel">
          {{ $t('general.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isAddingEvent: 'hunt/events/isAddingEvent'
    }),

    activeClass () {
      return this.isAddingEvent ? 'is-active' : ''
    }
  },

  methods: {
    cancel () {
      this.$store.commit('hunt/events/isAddingEvent', false)
    }
  }
}
</script>
