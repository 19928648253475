<template>
  <SummarySection :title="$t('hunt.participants')">
    <div v-if="!isLoading" class="push-bottom">
      <div v-for="member in members" :key="member.id" class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <ProfilePicture :userId="Number(member.userId)" size="small" round />
          </div>

          <div class="level-item">
            <div>
              <p class="member-name">{{ member.getFullName() }}</p>

              <span v-if="isHuntLeader(member) || isTrackingHunter(member)" class="tags">
                <span v-if="isHuntLeader(member)" class="tag is-link is-light">{{ $t('hunt.huntLeader.title') }}</span>
                <span v-if="isTrackingHunter(member)" class="tag is-link is-light">{{ $t('hunt.trackingHunter.title') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <ContentPlaceholders
        v-for="i in 3"
        :key="i"
        :rounded="true"
        class="push-bottom-xs">
        <ContentPlaceholdersHeading :img="true" />
      </ContentPlaceholders>
    </div>
  </SummarySection>
</template>

<script>
import { mapGetters } from 'vuex'
import SummarySection from './SummarySection.vue'

export default {

  components: {
    SummarySection
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      members: 'hunt/participants/participants'
    }),

    isLoading () {
      return this.members === null
    }
  },

  methods: {
    isHuntLeader (member) {
      return member.userId === this.hunt.huntLeaderUserId
    },

    isTrackingHunter (member) {
      return member.userId === this.hunt.afterSearchUserId
    }
  }

}
</script>

<style scoped>
.member-name {
  margin-bottom: 0;
}
</style>
