<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      $map: null,
      $polygon: null,
      $polygonBounds: null
    }
  },

  computed: {
    ...mapGetters({
      markers: 'hunt/map/markers',
      subAreas: 'hunt/map/subAreas',
      boundary: 'hunt/map/boundary',
      drives: 'hunt/drives/getAll',
      selectedDrive: 'hunt/drives/selectedDrive'
    }),

    drive () {
      if (this.drives === null) {
        return null
      }

      const i = this.drives.findIndex(drive => drive.subAreaId === null)
      return i !== -1 ? this.drives[i] : null
    }
  },

  watch: {
    selectedDrive (newDrive, lastDrive) {
      if (this.drive !== null) {
        if (newDrive === this.drive) {
          this.updateMarkerAssignments(true)
          this.fitBounds()
          this.hideSubAreas()
        } else if (lastDrive === this.drive) {
          this.updateMarkerAssignments(false)
        }
      }

      if (newDrive === null) {
        this.fitBounds()
      }
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const polygon = new window.google.maps.Polygon({
      map: this.$map,
      clickable: false,
      editable: false,
      paths: this.boundary.coordinates,
      strokeColor: '#6666FF',
      strokeWeight: 3,
      strokeOpacity: 1,
      fillOpacity: 0
    })

    if (polygon) {
      this.$polygonBounds = this.getBounds()
      this.$polygon = polygon

      this.fitBounds()
    } else {
      console.error('Failed to create polygon for boundary.')
    }
  },

  destroyed () {
    if (this.$polygon) {
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.boundary.coordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    },

    hideSubAreas () {
      this.subAreas.forEach(subArea => {
        subArea.visible = false
      })
    },

    fitBounds () {
      this.$map.fitBounds(this.$polygonBounds)
    },

    updateMarkerAssignments (setOrClear) {
      this.drive.assignments.forEach(assignment => {
        if (assignment.markerId !== null) {
          const i = this.markers.findIndex(marker => marker.id === assignment.markerId)
          if (i !== -1) {
            this.markers[i].user = setOrClear ? assignment : null
          }
        }
      })
    }
  }
}
</script>
