<template>
  <Portal to="modal">
    <div class="modal is-active hunt-playback">
      <div class="modal-background"></div>

      <div class="modal-content">
        <GoogleMaps>
          <ZoomButtons />
          <PlaybackControls />

          <PlaybackToolbar
            :is-active="playbackPanelActive"
            @open-panel="openPanel"
            @close-panel="closePanel"
            @close-modal="closeModal">
          </PlaybackToolbar>

          <PlaybackPanel :is-active="playbackPanelActive" />

          <HuntBoundary v-if="boundary && boundary.coordinates.length > 0" />

          <EventMarker v-for="event in events" :key="event.id" :event="event" />
          <HuntMarker v-for="marker in markers" :key="marker.id" :marker="marker" />
          <HunterMarker v-for="hunter in hunters" :key="hunter.id" :hunter="hunter" />
          <GpsDeviceMarker v-for="gpsDevice in gpsDevices" :key="gpsDevice.id" :gpsDevice="gpsDevice" />
        </GoogleMaps>

        <PlaybackAnimationHandler />
      </div>
    </div>
  </Portal>
</template>

<script>
import { mapGetters } from 'vuex'

import EventMarker from '@/components/map/markers/EventMarker.vue'
import HunterMarker from '@/components/map/markers/HunterMarker.vue'
import GpsDeviceMarker from '@/components/map/markers/GpsDeviceMarker.vue'

import PlaybackToolbar from './PlaybackToolbar.vue'
import PlaybackControls from './playbackControls/PlaybackControls.vue'

import PlaybackPanel from './playbackPanel/PlaybackPanel.vue'

import PlaybackAnimationHandler from './playbackAnimationHandler'

import HuntBoundary from './../map/HuntBoundary'
import HuntMarker from './../map/HuntMarker'

export default {
  components: {
    EventMarker,
    HuntMarker,
    HunterMarker,
    GpsDeviceMarker,
    HuntBoundary,

    PlaybackToolbar,
    PlaybackAnimationHandler,
    PlaybackControls,

    PlaybackPanel
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      playbackPanelActive: false
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      boundary: 'hunt/map/boundary',
      markers: 'hunt/map/markers',
      events: 'hunt/events/events',
      hunters: 'hunt/playback/hunters',
      gpsDevices: 'hunt/playback/gpsDevices'
    }),

    activeClass () {
      return this.isActive ? 'is-active' : ''
    },

    isDesktop () {
      return this.$mq.desktop
    }
  },

  mounted () {
    if (this.isDesktop) {
      this.openPanel()
    }
  },

  methods: {
    closeModal () {
      this.$emit('close')
      this.showMarkers()
    },

    showMarkers () {
      this.markers.forEach(marker => {
        marker.visible = true
      })
    },

    openPanel () {
      this.playbackPanelActive = true
    },

    closePanel () {
      this.playbackPanelActive = false
    }
  }
}
</script>

<style lang="scss">
.hunt-playback {
  .tag {
    position: absolute !important;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }

  .map {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .toolbar {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    // .map {
    //   max-height: calc(100% - 5rem);
    // }
  }

  .modal-content {
    background: white;
    height: calc(100vh - 5rem);
    width: calc(100vw - 5rem);
    display: flex;
    border-radius: 0.5rem;
    transition: all 0.15s ease-out;

    .map-is-fullscreen & {
      max-height: 100vh;
      height: 100vh;
      width: 100vw;
      border-radius: 0;
    }

    @media screen and (max-width: 768px) {
      max-height: 100vh;
      height: 100vh;
      width: 100vw;
      border-radius: 0;
    }

    aside {
      border-left: 1px solid rgb(209, 216, 224);
      width: 22.5rem;
      flex: 1 1 auto;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .tabs {
    margin: 0;
  }

  .tabs-details {
    flex: 1 1 auto;
    height: calc(100% - 3.0625rem);
    overflow-y: hidden;

    > div {
      flex: 1 1 auto;
      height: 100%;
    }
  }
}
</style>
