<template>
  <div class="playback-participants-tab">
    <div v-if="hunters.length > 0" class="list is-hoverable">
      <HunterListItem
        v-for="hunters in hunters"
        :key="hunters.id"
        :hunter="hunters">
      </HunterListItem>
    </div>

    <h5 class="title is-5 push-bottom-xs">
      {{ $t('hunt.dogs') }}
    </h5>

    <div v-if="gpsDevices.length > 0" class="list">
      <GpsDeviceListItem
        v-for="gpsDevice in gpsDevices"
        :key="gpsDevice.id"
        :gpsDevice="gpsDevice">
      </GpsDeviceListItem>
    </div>

    <p v-if="gpsDevices.length === 0" class="has-text-grey">
      {{ $t('hunt.noDogsInHunt') }}
    </p>

    <h5 class="title is-5 push-bottom-xs">
      {{ $t('hunt.trailLength') }}
    </h5>

    <div class="level push-bottom-xs">
      <div class="level-left">
        <label class="label text-is-normal is-marginless">{{ $t('hunt.hunters') }}</label>
      </div>

      <div class="level-right">
        <TrailLength v-model="hunterTrailLength" />
      </div>
    </div>

    <div class="level">
      <div class="level-left">
        <label class="label text-is-normal is-marginless">{{ $t('hunt.dogs') }}</label>
      </div>

      <div class="level-right">
        <TrailLength v-model="gpsTrailLength" />
      </div>
    </div>

    <p class="is-marginless">
      <a href="https://support.wehuntapp.com/en/support/solutions" target="_blank">
        <span>{{ $t('general.help') }}</span>
        <Icon name="icon-help-circle" />
      </a>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HunterListItem from './components/HunterListItem.vue'
import GpsDeviceListItem from './components/GpsDeviceListItem.vue'
import TrailLength from './components/TrailLength.vue'

export default {
  components: {
    HunterListItem,
    GpsDeviceListItem,
    TrailLength
  },

  computed: {
    ...mapGetters({
      hunters: 'hunt/playback/hunters',
      gpsDevices: 'hunt/playback/gpsDevices'
    }),

    hunterTrailLength: {
      get () {
        return this.$store.getters['hunt/playback/hunterTrailLength']
      },

      set (value) {
        this.$store.commit('hunt/playback/setHunterTrailLength', value)
      }
    },

    gpsTrailLength: {
      get () {
        return this.$store.getters['hunt/playback/gpsTrailLength']
      },

      set (value) {
        this.$store.commit('hunt/playback/setGpsTrailLength', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playback-participants-tab {
  height: 100%;
  padding: 1.5rem;
  overflow-y: auto;
}

.list-item {
  min-height: 3rem;

  .title {
    font-weight: 400;
  }

  &.is-hoverable {
    &:hover {
      background: #f5f5f5;
    }
  }

  &.is-active {
    background: #f5f5f5;
    color: inherit;

    .title {
      color: #eb914e;
    }
  }
}
</style>
