<template>
  <div class="select is-small">
    <select :value="value" @change="update">
      <option :value="0">0 min</option>
      <option :value="600">10 min</option>
      <option :value="3600">60 min</option>
      <option :value="21600">6 h</option>
      <option :value="43200">12 h</option>
      <option :value="86400">24 h</option>
      <option :value="172800">48 h</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    }
  },

  methods: {
    update (value) {
      this.$emit('input', Number(value.target.value))
    }
  }
}
</script>
