<template>
  <SummarySection :title="$t('hunt.huntableGames.title')" :is-expanded="isInitiallyExpanded">
    <ContentPlaceholders v-if="isLoading" :rounded="true" class="push-bottom">
      <ContentPlaceholdersText :lines="3" />
    </ContentPlaceholders>

    <div v-else>
      <GameSummarySectionTable v-if="huntableGames.length > 0">
        <GameSummarySectionTableRow
          v-for="game in huntableGames"
          :key="game.id"
          :game="game">
        </GameSummarySectionTableRow>
      </GameSummarySectionTable>

      <span v-if="huntableGames.length === 0">
        {{ $t('hunt.huntableGames.noHuntableGamesSelected') }}
      </span>
    </div>
  </SummarySection>
</template>

<script>
import { mapGetters } from 'vuex'

import SummarySection from './SummarySection.vue'
import GameSummarySectionTable from './GameSummarySectionTable.vue'
import GameSummarySectionTableRow from './GameSummarySectionTableRow.vue'

export default {
  components: {
    SummarySection,
    GameSummarySectionTable,
    GameSummarySectionTableRow
  },

  computed: {
    ...mapGetters({
      huntableGames: 'hunt/games/games'
    }),

    isLoading () {
      return this.huntableGames === null
    },

    isInitiallyExpanded () {
      return this.huntableGames !== null ? this.huntableGames.length === 0 : false
    }
  }
}
</script>
