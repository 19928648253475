<template>
  <IconBars :bars="accuracy" />
</template>

<script>
export default {
  props: {
    meters: {
      type: Number,
      default: 0 // 0 - 100
    }
  },

  computed: {
    accuracy () {
      // TODO: Rewrite this pile later
      if (this.isBetween(this.meters, 0, 25)) {
        return 4
      } else if (this.isBetween(this.meters, 25, 50)) {
        return 3
      } else if (this.isBetween(this.meters, 50, 75)) {
        return 2
      } else if (this.isBetween(this.meters, 75, 100)) {
        return 1
      } else {
        return ''
      }
    }
  },

  methods: {
    isBetween (x, min, max) {
      return x >= min && x <= max
    }
  }
}
</script>
