<template>
  <Fragment>
    <form v-if="games">
      <div class="field">
        <label class="label" for="game">{{ $t('hunt.gameTitle') }}</label>

        <div class="control">
          <div
            v-if="event"
            id="game"
            :class="{ 'is-loading': games === null }"
            class="select">
            <select v-model="game">
              <option value>{{ $t('hunt.events.selectSpecies') }}</option>
              <option
                v-for="(animal, i) in games"
                :key="i"
                :value="animal.id">
                {{ animal.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label" for="note">{{ $t('general.comment') }}</label>

        <div class="control">
          <textarea
            id="note"
            v-model="note"
            class="textarea"></textarea>
        </div>
      </div>
    </form>

    <ContentPlaceholders v-if="!games" :rounded="true">
      <ContentPlaceholdersText :lines="3" />
    </ContentPlaceholders>
  </Fragment>
</template>

<script>
// import dayjs from 'dayjs'
import store from '@/store/'

export default {

  store,

  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    hunt () { return this.options['hunt'] },

    games () {
      return this.$store.getters['hunt/games/availableGames']
    },

    event: {
      get () {
        return this.options['event']
      },
      set (event) {
        this.options['event'] = event
      }
    },

    note: {
      get () { return this.event.note },
      set (value) { this.event.note = value }
    },

    game: {
      get () {
        return this.event.animalId || ''
      },
      set (animalId) {
        const $game = this.games.filter(animal => animal.id === animalId)[0]

        this.event.name = $game.name
        this.event.animalId = $game.id
        this.event.category = $game.category
      }
    },

    isValid () { return true }
  }

}
</script>
