<template>
  <Chat class="playback-chat">
    <p v-if="visibleMessages.length === 0" class="has-text-grey no-messages-sent">
      {{ $t('hunt.noMessagesYet') }}
    </p>

    <ChatMessages>
      <ChatMessage
        v-for="message in sortedMessages"
        :key="message.id"
        :createdBy="getParticipantName(message.createdBy)"
        :me="isMe(message)"
        :message="message">
      </ChatMessage>
    </ChatMessages>
  </Chat>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      participantNames: []
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      messages: 'hunt/chat/messages',
      participants: 'hunt/participants/participants'
    }),

    visibleMessages () {
      return this.messages.filter(message => message.visible)
    },

    sortedMessages () {
      return this.visibleMessages && this.visibleMessages.slice().sort((a, b) => a.createdAt - b.createdAt)
    }
  },

  methods: {
    isMe (message) {
      const userId = this.$store.getters['auth/getUserId']
      return message.createdBy === userId
    },

    getParticipantName (participantId) {
      let name = this.participantNames[participantId]

      if (name !== undefined) {
        return name
      }

      const participant = this.getParticipant(participantId)

      name = participant !== null
        ? participant.getFullName()
        : this.$i18n.t('hunt.unknownParticipant')

      this.participantNames[participantId] = name

      return name
    },

    getParticipant (participantId) {
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].id === participantId) {
          return this.participants[i]
        }
      }

      return null
    },

    toggleChatPanel () {
      this.$store.commit('hunt/playback/toggleChatPanel')
    }
  }
}
</script>

<style lang="scss">
.playback-chat {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

p.no-messages-sent {
  padding: 1.5rem;
  margin: 0 !important;
}
</style>
