<template>
  <TableRow>
    <TableCell class="is-narrow">
      {{ game.name }}
    </TableCell>

    <TableCell class="has-text-grey">
      <small>{{ game.note }}</small>
    </TableCell>
  </TableRow>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      required: true
    }
  }
}
</script>
