<template>
  <div class="select is-small">
    <select v-model="speed">
      <option :value="1">1x</option>
      <option :value="2">2x</option>
      <option :value="5">5x</option>
      <option :value="10">10x</option>
      <option :value="25">25x</option>
      <option :value="50">50x</option>
    </select>
  </div>
</template>

<script>
export default {
  computed: {
    speed: {
      get () {
        return this.$store.getters['hunt/playback/speed']
      },

      set (speed) {
        return this.$store.dispatch('hunt/playback/speed', speed)
      }
    }
  }
}
</script>
