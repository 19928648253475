<template>
  <MapMarker
    :name="event.name"
    :icon="icon"
    :location="event.location"
    :visible="event.visible"
    :animation="animation">
  </MapMarker>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.event.type)
    },

    animation () {
      return this.event.visible ? 'drop' : null
    }
  }
}
</script>
