<template>
  <Fragment>
    <div class="hunt-toolbar">
      <Level mobile>
        <LevelLeft>
          <LevelItem>
            <ButtonGroup class="is-marginless level-left">
              <Button
                v-if="isPlanning"
                type="primary"
                :title="$t('general.sendInvites')"
                @click="sendInvites">
              </Button>

              <Button
                :title="$t('general.cancel')"
                @click="cancel">
              </Button>

              <Button
                v-if="isFinished"
                :disabled="!canPlay"
                type="secondary"
                icon="icon-play"
                :title="$t('hunt.play')"
                @click="showPlaybackModal">
              </Button>
            </ButtonGroup>
          </LevelItem>

          <LevelItem v-if="isSaving">
            <small>{{ $t('hunt.saving') }}</small>
          </LevelItem>
        </LevelLeft>

        <LevelRight v-if="hunt !== null" class="is-marginless">
          <Button
            v-if="isAdmin"
            :loading="isDeleting"
            :disabled="isDeleting || isLoading"
            type="danger"
            @click="confirmRemove">
            <Icon name="icon-trash" />
          </Button>

          <Button
            v-else-if="isMember"
            :disabled="isLoading"
            type="danger"
            :title="$t('hunt.leave')"
            @click="confirmLeave">
          </Button>
        </LevelRight>
      </Level>
    </div>

    <HuntSummaryModal
      v-if="isHuntSummaryModalActive"
      @close="hideHuntSummaryModal">
    </HuntSummaryModal>

    <PlaybackModal
      v-if="isPlaybackModalActive"
      @close="hidePlaybackModal">
    </PlaybackModal>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

import PlaybackModal from './playback/PlaybackModal.vue'
import HuntSummaryModal from './summary/HuntSummaryModal.vue'

export default {
  components: {
    PlaybackModal,
    HuntSummaryModal
  },

  data () {
    return {
      isHuntSummaryModalActive: false,
      isPlaybackModalActive: false,

      isDeleting: false,
      sendingInvites: false
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      hunt: 'hunt/hunt',
      messages: 'hunt/chat/messages',
      events: 'hunt/events/events',
      participants: 'hunt/participants/participants',

      boundary: 'hunt/map/boundary',
      markers: 'hunt/map/markers',
      subAreas: 'hunt/map/subAreas',
      forbiddenAreas: 'hunt/map/forbiddenAreas',

      hunterLocations: 'hunt/playback/hunterLocations',
      gpsLocations: 'hunt/playback/gpsLocations'
    }),

    isAdmin () {
      return this.hunt && this.hunt.createdBy === this.userId
    },

    isMember () {
      return this.$store.getters['hunt/participants/isMember'](this.userId)
    },

    canPlay () {
      const loading = [
        this.hunt,
        this.events,
        this.messages,
        this.participants,
        this.markers,
        this.hunterLocations,
        this.gpsLocations
      ]

      for (const state of loading) {
        if (state === null) {
          return false
        }
      }

      return true
    },

    isLoading () {
      return this.hunt === null || this.events === null || this.messages === null || this.participants === null || this.markers === null || this.subAreas === null || this.forbiddenAreas === null
    },

    isSaving () {
      return this.$store.getters['hunt/state'] === 'loading'
    },

    isPlanning () { return this.hunt && this.hunt.status === 'planning' },
    isPlanned () { return this.hunt && this.hunt.status === 'planned' },
    isFinished () { return this.hunt && this.hunt.status === 'finished' }
  },

  methods: {
    showPlaybackModal () {
      this.isPlaybackModalActive = true
    },

    hidePlaybackModal () {
      this.isPlaybackModalActive = false
    },

    showHuntSummaryModal () {
      this.isHuntSummaryModalActive = true
    },

    hideHuntSummaryModal () {
      this.isHuntSummaryModalActive = false
    },

    cancel () {
      this.$emit('cancel')
    },

    async confirmRemove () {
      const response = await this.$removeDialog.confirm()

      if (response.ok) {
        this.removeHunt()
      }
    },

    async removeHunt () {
      this.isDeleting = true

      try {
        const huntAreaId = this.hunt.huntAreaId

        await this.$store.dispatch('hunt/delete', this.hunt)
        this.$router.push(`/area/${huntAreaId}/hunts`)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.removeHuntFailedErrorMessage'))
        console.error(error)
      }

      this.isDeleting = false
    },

    confirmLeave () {
      this.$emit('leave')
    },

    sendInvites () {
      if (this.isHuntValid()) {
        this.showHuntSummaryModal()
      } else {
        this.$dialog.alert({
          title: this.$i18n.t('hunt.mandatoryFieldsDialogTitle'),
          message: this.$i18n.t('hunt.mandatoryFieldsDialogText'),
          ok: this.$i18n.t('general.close')
        })
      }
    },

    isHuntValid () {
      const hasName = this.hunt.name.length > 0
      const hasStartDate = this.hunt.startsAt !== null
      const hasEndDate = this.hunt.endsAt !== null
      const hasHuntLeader = this.hunt.huntLeaderUserId !== null
      const hasNote = this.hunt.note.length > 0

      return hasName && hasStartDate && hasEndDate && hasHuntLeader && hasNote
    }
  }
}
</script>

<style lang="scss" scoped>
.hunt-toolbar {
  border-top: 1px solid #dbdbdb;
  background: #f5f5f5;
  width: 100%;
  margin: auto 0 0 0 !important;
  padding: 0.75rem;

  button {
    margin-bottom: 0 !important;
  }
}
</style>
