<template>
  <Button class="is-small-touch" type="white" @click="togglePlay">
    <Icon :name="icon" />
  </Button>
</template>

<script>
export default {
  computed: {
    status () {
      return this.$store.getters['hunt/playback/status']
    },

    icon () {
      return this.status === 'started' ? 'icon-pause' : 'icon-play'
    }
  },

  methods: {
    togglePlay () {
      if (this.status === 'stopped' || this.status === 'paused') {
        this.$store.dispatch('hunt/playback/play')
      } else {
        this.$store.dispatch('hunt/playback/pause')
      }
    }
  }
}
</script>
