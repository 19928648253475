<template>
  <div class="range-slider has-label">
    <input
      v-model="elapsedTime"
      :max="duration"
      :step="1"
      type="range"
      min="0" />

    <label class="range-label">{{ elapsedAsDateTime }} / {{ durationAsDateTime }}</label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      duration: 'hunt/playback/duration',
      speed: 'hunt/playback/speed'
    }),

    elapsedTime: {
      get () {
        return this.$store.getters['hunt/playback/elapsedTime']
      },

      set (elapsedTime) {
        return this.$store.dispatch('hunt/playback/elapsedTime', Number(elapsedTime))
      }
    },

    elapsedAsDateTime () {
      var startTimeInMs = this.hunt.startsAt.getTime()
      var elapsedInMs = this.elapsedTime * 1000

      let dateTime = new Date()
      dateTime.setTime(startTimeInMs + elapsedInMs)

      let dateFormat = this.hunt.isMultipleDays() ? 'D MMM HH:mm:ss' : 'HH:mm:ss'

      return this.$dayjs(dateTime).format(dateFormat)
    },

    durationAsDateTime () {
      let dateFormat = this.hunt.isMultipleDays() ? 'D MMM HH:mm:ss' : 'HH:mm:ss'

      return this.$dayjs(this.hunt.startsAt.getTime() + (this.duration * 1000)).format(dateFormat)
    }
  }
}
</script>
