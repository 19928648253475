<template>
  <Button class="is-small-touch" type="white" @click="stop">
    <Icon name="icon-square" />
  </Button>
</template>

<script>
export default {
  methods: {
    stop () {
      this.$store.dispatch('hunt/playback/stop')
    }
  }
}
</script>
