<template>
  <div v-show="isActive" class="playback-panel box">
    <Tabs>
      <Tab :name="$t('hunt.participants')" selected>
        <PlaybackParticipantsTab />
      </Tab>

      <Tab :name="$t('hunt.chat')">
        <PlaybackChatTab />
      </Tab>

      <Tab :name="$t('hunt.events.title')">
        <PlaybackEventTab />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import PlaybackParticipantsTab from './PlaybackParticipantsTab.vue'
import PlaybackChatTab from './PlaybackChatTab.vue'
import PlaybackEventTab from './PlaybackEventTab.vue'

export default {
  components: {
    PlaybackParticipantsTab,
    PlaybackChatTab,
    PlaybackEventTab
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.playback-panel {
  position: absolute;
  top: 5.5rem;
  bottom: 8rem;
  left: 1.5rem;
  width: 21.5rem;
  padding: 0;
  overflow: hidden;
}
</style>
