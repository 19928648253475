<template>
  <Dialog
    :title="$t('hunt.confirmLeaveTitle')"
    :close-enabled="!leavingHunt"
    :buttons="buttons"
    @close="close">
    {{ $t('hunt.confirmLeaveMessage') }}
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  data () {
    return {
      leavingHunt: false
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt'
    }),

    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        disabled: this.leavingHunt,
        click: this.close
      }, {
        type: 'danger',
        title: this.$i18n.t('hunt.leave'),
        loading: this.leavingHunt,
        click: this.leaveHunt
      }]
    },

    isGuestHunt () {
      const huntAreas = this.$store.getters['huntarea/areas']

      for (const huntArea of huntAreas) {
        if (huntArea.id === this.hunt.huntAreaId) {
          return false
        }
      }

      return true
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async leaveHunt () {
      this.leavingHunt = true

      try {
        await this.$store.dispatch('hunt/leave')

        if (this.isGuestHunt) {
          this.redirectToCalendar()
        } else {
          this.redirectToHunts()
        }
      } catch (error) {
        console.error(error)
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      } finally {
        this.leavingHunt = false
      }
    },

    redirectToHunts () {
      this.$router.push({
        name: 'hunts',
        params: {
          huntAreaId: this.hunt.huntAreaId
        }
      })
    },

    redirectToCalendar () {
      this.$router.push({ name: 'calendar' })
    }
  }
}
</script>
