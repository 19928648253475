<template>
  <div class="push-bottom-xl">
    <Label :label="$t('hunt.summary.inviteTitle')" />
    <div class="note" v-html="hunt.note"></div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt'
    })
  }
}
</script>

<style scoped>
.note {
  background-color: rgb(245, 245, 245);
  border: 1px solid lightgrey;
  padding: 1.5rem;
}
</style>
