import colors from '@/components/map/utils/colors'
import { mapGetters } from 'vuex'

import HuntBoundary from './map/HuntBoundary.vue'

import HuntEventModel from '@/api/hunt/events/huntEventModel'
import AddEventMarker from './map/AddEventMarker.vue'
import HuntMarker from './map/HuntMarker.vue'
import EventMarker from '@/components/map/markers/EventMarker.vue'
import HuntSubArea from './map/HuntSubArea.vue'

import AssignNotification from './map/AssignNotification.vue'
import EventNotification from './map/EventNotification.vue'

import EventModal from './tabs/events/EventModal.vue'
import PlaybackModal from './playback/PlaybackModal.vue'
import HuntSummaryModal from './summary/HuntSummaryModal.vue'
import HuntConfirmLeaveDialog from './HuntConfirmLeaveDialog.vue'

import HuntToolbar from './HuntToolbar.vue'
import { HUNT_STATUS_FINISHED } from '../../api/hunt/huntModel'

export default {
  components: {
    HuntBoundary,
    HuntMarker,
    HuntSubArea,
    HuntSummaryModal,
    AddEventMarker,
    EventMarker,
    AssignNotification,
    EventNotification,
    PlaybackModal,
    HuntConfirmLeaveDialog,
    HuntToolbar
  },

  data () {
    return {
      prevRoute: null, // The previous path/route, which we shall go to when 'Cancel' is clicked
      overflow: true,
      confirmLeaveHunt: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/data',
      huntArea: 'huntarea/selected',
      huntAreas: 'huntarea/areas',
      hunt: 'hunt/hunt',
      participants: 'hunt/participants/participants',
      messages: 'hunt/chat/messages',

      boundary: 'hunt/map/boundary',
      markers: 'hunt/map/markers',
      subAreas: 'hunt/map/subAreas',
      forbiddenAreas: 'hunt/map/forbiddenAreas',

      events: 'hunt/events/events',
      eventType: 'hunt/events/type'
    }),

    isPlanning () { return this.hunt && this.hunt.status === 'planning' },
    isPlanned () { return this.hunt && this.hunt.status === 'planned' },
    isFinished () { return this.hunt && this.hunt.status === 'finished' },

    isLoading () {
      return this.hunt === null || this.hunt.status === HUNT_STATUS_FINISHED
    },

    isAdmin () {
      const userId = this.$store.getters['auth/getUserId']
      return this.hunt !== null ? this.hunt.createdBy === userId : false
    },

    isAddingEvent: {
      get () { return this.$store.getters['hunt/events/isAddingEvent'] },
      set (state) { this.$store.commit('hunt/events/isAddingEvent', state) }
    },

    overflowStyle () {
      return this.overflow ? 'hidden' : ''
    }
  },

  watch: {
    '$route' (to, from) {
      // The chat needs special overflowing
      this.matchRoute(to.name)
    },

    huntAreas () {
      if (this.huntAreas !== null) {
        this.read()
      }
    }
  },

  created () {
    if (this.huntAreas !== null) {
      this.read()
    }

    this.matchRoute(this.$route.name)
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },

  destroyed () {
    this.$store.commit('hunt/setHunt', null)
  },

  methods: {
    cancel () {
      // If the user refreshed the page this will be the dashboard by default
      this.$router.push(this.prevRoute)
    },

    matchRoute (path) {
      this.overflow = (path === 'chat')
    },

    isGuestHunt () {
      const huntAreas = this.$store.getters['huntarea/areas'] || []
      return huntAreas.findIndex(huntArea => huntArea.id === this.hunt.huntAreaId) === -1
    },

    async read () {
      try {
        const hunt = await this.$store.dispatch('hunt/read', this.$route.params.huntId)

        if (hunt.status === HUNT_STATUS_FINISHED) {
          this.redirectToReport(hunt)
          return
        }
        // this.$store.commit('hunt/setHuntById', this.$route.params.huntId)
        // const hunt = this.hunt

        if (this.isGuestHunt()) {
          this.$store.commit('huntarea/select', null)
        } else {
          // Populate sidebar with Hunt Area specific navigation
          if (!this.huntArea || this.huntArea.id !== hunt.huntAreaId) {
            this.$store.dispatch('huntarea/read', hunt.huntAreaId)
          }
        }

        await this.$store.dispatch('hunt/participants/read', hunt.id)
        this.$store.dispatch('hunt/drives/read')
        this.$store.dispatch('hunt/games/read', hunt.id)

        await Promise.all([
          this.readAvailableGames(),

          this.$store.dispatch('huntarea/members/read', hunt.huntAreaId),

          this.$store.dispatch('hunt/map/readBoundary', hunt.huntAreaId),
          this.$store.dispatch('hunt/map/readMarkers', hunt.huntAreaId),
          this.$store.dispatch('hunt/map/readSubAreas', hunt.huntAreaId),
          this.$store.dispatch('hunt/map/readForbiddenAreas', hunt.huntAreaId)
        ])
      } catch (error) {
        this.$notification.danger(error.toString())
        console.error(error)
      }
    },

    async readAvailableGames () {
      if (!this.hunt.startsAt) return

      const county = await this.$store.dispatch('hunt/games/readCounty', this.hunt.huntAreaId)
      const countryCode = this.$store.getters['ui/getCountryCode']

      this.$store.dispatch('hunt/games/readAvailable', {
        county: county,
        date: this.$dayjs(this.hunt.startsAt).format('YYYY-MM-DD'),
        countryCode: countryCode
      })
    },

    async addedEventMarker (latLng) {
      this.isAddingEvent = false

      const response = await this.$dialog.prompt({
        title: this.$t('general.add'),
        component: EventModal,
        ok: this.$t('general.add'),
        data: {
          hunt: this.hunt,
          event: new HuntEventModel({
            type: this.eventType,
            location: latLng,
            createdAt: new Date(),
            createdBy: this.user.id,
            status: 'loading'
          })
        }
      })

      if (response.ok) {
        this.createEvent(response.dialog.data.event)
      }
    },

    async createEvent (event) {
      try {
        await this.$store.dispatch('hunt/events/create', {
          huntId: this.hunt.id,
          event: event
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.events.createFailedErrorMessage'))
        console.error(error)
      }
    },

    color (type) {
      return colors[type] || colors.default
    },

    confirmLeave () {
      this.confirmLeaveHunt = true
    },

    async redirectToReport (hunt) {
      const report = await this.$store.dispatch('huntarea/reports/getByHunt', hunt)

      this.$router.push({
        name: 'view-report-no-huntarea-general-tab',
        params: { reportId: report.id },
        query: { huntId: hunt.id }
      })
    }
  }
}
