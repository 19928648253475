<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      $map: null,
      $drawingManager: null
    }
  },

  watch: {
    active () {
      const mode = this.active ? window.google.maps.drawing.OverlayType.MARKER : null
      const map = this.active ? this.$map : null

      this.$drawingManager.setDrawingMode(mode)
      this.$drawingManager.setMap(map)
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    this.$drawingManager = new window.google.maps.drawing.DrawingManager({
      map: this.$map,
      drawingControl: false
    })

    window.google.maps.event.addListener(this.$drawingManager, 'markercomplete', marker => {
      if (this.active) {
        this.$emit('marker-added', marker.getPosition().toJSON())
      }

      marker.setMap(null)
    })
  },

  render () {
    return ''
  }
}
</script>
