<template>
  <Dialog :title="$t('hunt.summary.title')" :close-enabled="!submitting" :buttons="buttons" @close="close">
    <Alert type="info" :message="$t('hunt.summary.summaryHelpText')" />

    <Divider />
    <HuntDateSummarySection />
    <Divider />
    <InvitationTextSummarySection />
    <MemberSummarySection />
    <GameSummarySection />
    <AssignmentSummarySection />
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import HuntDateSummarySection from './sections/HuntDateSummarySection.vue'
import InvitationTextSummarySection from './sections/InvitationTextSummarySection.vue'
import GameSummarySection from './sections/GameSummarySection.vue'
import AssignmentSummarySection from './sections/AssignmentSummarySection.vue'
import MemberSummarySection from './sections/MemberSummarySection.vue'

export default {
  components: {
    Dialog,
    HuntDateSummarySection,
    InvitationTextSummarySection,
    GameSummarySection,
    MemberSummarySection,
    AssignmentSummarySection
  },

  data () {
    return {
      submitting: false
    }
  },

  computed: {
    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        disabled: this.submitting,
        click: this.close
      }, {
        type: 'primary',
        title: this.$i18n.t('general.sendInvites'),
        loading: this.submitting,
        click: this.submit
      }]
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async submit () {
      this.submitting = true

      try {
        let hunt = this.$store.getters['hunt/hunt']

        hunt = await this.$store.dispatch('hunt/plan', hunt)
        this.$store.commit('hunt/setHunt', hunt)
        this.close()

        this.$router.push(`/area/${hunt.huntAreaId}/hunts`)
        this.$notification.success(this.$i18n.t('hunt.huntCreatedMessage'), 7500)
      } catch (error) {
        this.close()
        this.$notification.danger('Ett fel inträffade och det gick inte att skicka ut inbjudningar. Vänligen försök igen.')
        console.log(error)
      }
    }
  }
}
</script>
