<template>
  <MapMarker
    :name="gpsDevice.name"
    :location="gpsDevice.location"
    :icon="icon"
    :visible="gpsDevice.visible"
    :animation="animation"
    :clickable="true"
    :anchor="anchor"
    @click="onClick">
  </MapMarker>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    gpsDevice: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      $polyline: null,
      $map: null
    }
  },

  computed: {
    ...mapGetters({
      markerSize: 'map/markers/size',
      selectedParticipant: 'hunt/playback/selectedParticipant',
      gpsTrailLength: 'hunt/playback/gpsTrailLength',
      elapsedTime: 'hunt/playback/elapsedTime'
    }),

    icon () {
      return getMarkerTypeIconUrl('dog')
    },

    iconSize () {
      return this.markerSize * 32
    },

    animation () {
      return this.gpsDevice.visible ? 'drop' : null
    },

    selected () {
      return this.selectedParticipant === this.gpsDevice
    },

    color () {
      return this.gpsDevice.color
    },

    anchor () {
      return {
        x: this.iconSize / 2,
        y: this.iconSize / 2
      }
    }
  },

  watch: {
    gpsTrailLength (trailLength) {
      this.$polyline.setMap(trailLength > 0 ? this.$map : null)
      this.updateTrail()
    },

    elapsedTime () {
      this.updateTrail()
    },

    color () {
      if (this.$polyline) {
        this.$polyline.setOptions({
          strokeColor: this.gpsDevice.color
        })
      }
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    this.$polyline = new window.google.maps.Polyline({
      map: this.$map,
      clickable: false,
      path: [],
      strokeColor: this.color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    })
  },

  destroyed () {
    if (this.$polyline) {
      this.$polyline.setMap(null)
    }
  },

  methods: {
    updateTrail () {
      if (this.$polyline == null) {
        return
      }

      const hunt = this.$store.getters['hunt/hunt']
      const elapsedInMs = hunt.startsAt.getTime() + (this.elapsedTime * 1000)
      const trailLengthInMs = this.gpsTrailLength * 1000

      let path = []

      for (const gpsLocation of this.gpsDevice.trail) {
        if (gpsLocation.timestamp <= elapsedInMs && gpsLocation.timestamp >= (elapsedInMs - trailLengthInMs)) {
          path.push(new window.google.maps.LatLng(gpsLocation.location))
        }

        // If we reach the first location in the future, abort
        if (gpsLocation.timestamp > elapsedInMs) {
          break
        }
      }

      path.push(new window.google.maps.LatLng(this.gpsDevice.location))

      this.$polyline.setPath(path)
    },

    onClick () {
      this.$store.dispatch('hunt/playback/selectParticipant', !this.selected ? this.gpsDevice : null)
    }
  }
}
</script>
