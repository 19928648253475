<template>
  <div
    :class="[classObject]"
    class="list-item level is-marginless flex-important"
    @click="select">
    <div class="level-left">
      <div class="flex align-center">
        <figure class="image is-32x32">
          <img :src="icon" />
        </figure>

        <div class="push-left-xs">
          <p class="title is-6 is-marginless">
            {{ gpsDevice.name }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="gpsDevice.active" class="level-right">
      <span>{{ speed }} km/h</span>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {

  props: {
    gpsDevice: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      selectedParticipant: 'hunt/playback/selectedParticipant'
    }),

    classObject () {
      return {
        'is-dimmed': !this.gpsDevice.active,
        'is-clickable is-hoverable': this.gpsDevice.active,
        'is-active': this.selected
      }
    },

    selected () {
      return this.selectedParticipant === this.gpsDevice
    },

    speed () {
      return Math.round(this.gpsDevice.speed)
    },

    icon () {
      if (this.gpsDevice.active) {
        return getMarkerTypeIconUrl('dog')
      }

      return getMarkerTypeIconUrl('dog_off')
    }
  },

  methods: {
    select () {
      if (!this.gpsDevice.active) return
      this.$store.dispatch('hunt/playback/selectParticipant', !this.selected ? this.gpsDevice : null)
    }
  }

}
</script>
